<template>
    <CoreContainer :aria-label="$t('accessibility.newsletter')">
        <BaseContainerContent class="flex flex-col gap-4 xl:flex-row" :padding="{ vertical: 'none' }">
            <i18n-t
                tag="h3"
                class="vut-h3 flex-1"
                scope="global"
                keypath="sections.newsletter.be_in_the_loop"
            >
                <template #br>
                    <br>
                </template>
            </i18n-t>

            <NewsletterSubscribeComponent class="flex-1" />
        </BaseContainerContent>
    </CoreContainer>
</template>
