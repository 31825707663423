<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.9995 8.43799L0.3125 4.68799L1.6875 3.31299L3.9995 5.56299L9.3125 0.312988L10.6875 1.68799L3.9995 8.43799Z" fill="currentColor"/>
    </svg>

)
</script>
