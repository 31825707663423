<template>
    <div class="vut-navbar-search"
         :class="{
             'vut-navbar-search--active': isMobileSearchBarVersion
         }"
    >
        <!-- BaseUiPopup ? -->
        <BaseUiInput
            ref="inputComponentRef"
            v-model="modelQuery"
            :placeholder="$t('labels.search')"
            class="vut-navbar-search__input"
            :class="{
                'vut-navbar-search__input--mobile': isMobileSearchBarVersion
            }"
            variant="solid"
            clearable
            @keyup.enter="handleEnter"
        >
            <template #trailing>
                <IconSearch size="sm" class="mr-2" />
            </template>
        </BaseUiInput>

        <BaseUiButton
            color="white"
            class="vut-navbar-search__btn ml-auto !p-4 md:aspect-square lg:hidden"
            size="auto"
            variant="sharp"
            @click="isMobileSearchBarVersion = !isMobileSearchBarVersion"
        >
            <template #trailing>
                <IconSearch v-if="!isMobileSearchBarVersion" class="size-4 sm:size-5" />
                <IconCross v-else class="size-4" />
            </template>

        </BaseUiButton>

        <LazySearchResultsPopup
            v-if="wasResultsPopupMounted"
            ref="popupComponentRef"
            v-model="isResultsPopupOpen"
            v-model:query="searchQuery"
            :reference="inputComponentRef?.$el"
        />

        <BaseFullscreenBackground class="-z-10" :is-visible="isResultsPopupOpen" />
    </div>
</template>

<script lang="ts" setup>
import type { BaseUiInput } from '#components'

const isOverLgBreakpoint = useScssBreakpoints().greaterOrEqual('lg')
watch(isOverLgBreakpoint, (val) => {
    if (val) {
        isMobileSearchBarVersion.value = false
    }
})

const isMobileSearchBarVersion = ref<boolean>(false)

const inputComponentRef = useTemplateRef<ComponentInstance<typeof BaseUiInput>>('inputComponentRef')
const { modelQuery, searchQuery, handleEnter, isResultsPopupOpen, wasResultsPopupMounted } = useSearch(inputComponentRef, {
    navigateToResultsOnEnter: true,
})

</script>

<style lang="scss" scoped>
.vut-navbar-search {
    display: flex !important;
}

.vut-navbar-search--active {
    position: absolute;
    right: 0;
    left: 0;

    height: $vut-navbar-mobile-height;

    border-right: unset !important;

    z-index: 10;

    @include more-than('lg') {
        height: $vut-navbar-height;
    }
}

.vut-navbar-search__input {
    display: none !important;   // fix for different style order when loading

    padding-right: 1.875rem;
    padding-left: 2.75rem;

    flex: 1;

    @include more-than('lg') {
        display: flex !important;
    }
}

.vut-navbar-search__input--mobile {
    display: flex !important;
}
</style>
