<template>
    <BaseUtilCollapse v-model="isExpanded" :direction="direction" :duration="500">
        <slot />
    </BaseUtilCollapse>
</template>

<script lang="ts" setup>
import type { BaseUtilCollapseProps } from '@core/app/components/base/util/BaseUtilCollapse.vue'

const {
    direction,
} = defineProps<{
    direction?: BaseUtilCollapseProps['direction']
}>()

const isExpanded = ref<boolean>(false)

onMounted(() => {
    isExpanded.value = true
})

</script>

<style lang="scss" scoped>

</style>
