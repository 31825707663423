import type { ComponentInstance } from '@core/types/utility'

interface UseSearchOptions {
    /**
     * Whether to navigate to the search results page when the enter key
     * is pressed in the search input.
     * @default false
     */
    navigateToResultsOnEnter: boolean
}

export function useSearch(inputComponentRef: Ref<HTMLElement | ComponentInstance<unknown> | null>, options?: Partial<UseSearchOptions>) {
    const { is: isResultsPopupOpen, was: wasResultsPopupMounted } = useLazyMount()

    const modelQuery = ref<string>('')
    const _syncedQuery = ref<string>('')
    const searchQuery = computed({
        get() {
            return _syncedQuery.value
        },
        set(value) {
            modelQuery.value = value
            syncQuery()
        },
    })

    function syncQuery() {
        _syncedQuery.value = modelQuery.value
    }

    const syncQueryDebounced = useDebounceFn(syncQuery, 500)

    watch(modelQuery, (val) => {
        if (!val.trim()) {
            syncQuery()
            return
        }

        isResultsPopupOpen.value = true
        syncQueryDebounced()
    })

    const { getDynamicRoute } = useDynamicRoutes()

    function handleEnter() {
        syncQuery()
        isResultsPopupOpen.value = !options?.navigateToResultsOnEnter
        if (options?.navigateToResultsOnEnter) {
            navigateTo(getDynamicRoute('search', { query: { search_query: _syncedQuery.value } }))
        }
    }

    const route = useRoute()
    watch(() => route.path, () => {
        isResultsPopupOpen.value = false
    })

    const { focused } = useFocusWithin(inputComponentRef as Parameters<typeof useFocusWithin>[0])

    watch(focused, (isFocused) => {
        if (!isFocused) return

        // delay the opening in case the clear button was clicked
        // (not to open it immediately)
        setTimeout(() => {
            if (!focused.value) return
            isResultsPopupOpen.value = true
        }, 100)
    })

    onMounted(() => {
        // check if focus is already on the input
        if (unrefElement(inputComponentRef)?.contains(document.activeElement)) {
            isResultsPopupOpen.value = true
        }
    })

    return {
        isResultsPopupOpen,
        wasResultsPopupMounted,
        /**
         * The ref to use for the `v-model` binding of the input.
         * Do not use it directly for search, since it is not debounced.
         */
        modelQuery,
        /**
         * The debounced ref to use for the search query.
         *
         * Do not use this for `v-model` binding of the input.
         * You can change its value directly, though, when searching for a phrase, for example.
         * Changing the value of this ref results in the underlying `modelQuery` ref being updated too.
         */
        searchQuery,
        /**
         * Enter key handler function for the input.
         */
        handleEnter,
    }
}
