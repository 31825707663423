<template>
    <div>
        <BaseUiButton
            ref="cartMenuButton"
            :to="localePath('cart')"
            color="black"
            variant="sharp"
            class="!md:px-3 h-full !p-4 !normal-case md:aspect-auto md:!px-8"
            content-class="!gap-0"
            :aria-label="$t('labels.cart')"
            :aria-controls="cartMenuId"
            :aria-expanded="isCartMenuDropdownOpen"
        >
            <template #leading>
                <div class="relative">
                    <IconCart class="size-4 sm:size-5" />

                    <Transition>
                        <span v-if="cart" class="vut-cart__amount-indicator">
                            {{ cart.totalAmount }}
                        </span>
                    </Transition>
                </div>
            </template>

            <component
                :is="renderCollapse ? UtilExpandOnAppear : 'div'"
                v-if="cart"
                direction="horizontal"
                class="hidden md:block"
            >
                <span class="pl-6">
                    {{ getFormattedPrice(cart.taxedTotalPrice) }}
                </span>
            </component>
        </BaseUiButton>

        <!--  cart MENU  -->
        <LazyUiPopupCard
            v-if="cart?.totalAmount && isCartMenuDropdownMounted && $refs.cartMenuButton"
            :id="cartMenuId"
            ref="cartMenuPopup"
            v-model="isCartMenuDropdownOpen"
            :anchor="cartMenuButton?.$el"
            :safe-padding="0"
            :boundary="navbarEl"
            placement="bottom-start"
            size="lg"
            :offset="{
                mainAxis: 0,
                crossAxis: -1, // 1 px border offset from the reference button
            }"
            :z-index="zIndex"
            class="min-w-[350px]"
            content-class="flex flex-col gap-4"
        >
            <Transition>
                <div v-if="renderNotification" class="vut-cart__popup-notification vut-ff-secondary vut-letter-spacing-large uppercase">
                    <IconCheckmark aria-hidden />
                    {{ $t('cart.item_added_to_cart') }}
                </div>
            </Transition>

            <component
                :is="areItemsLoading ? 'div' : NuxtLink"
                v-for="item in (areItemsLoading ? Array.from({ length: cart.uniqueAmount ?? 0 }).fill(null) : items) as (InstanceType<typeof CartItemModel> | null)[]"
                :key="item ? item.id! : item"
                :to="areItemsLoading ? undefined : item?.getUrl() ?? ''"
                class="mb-2 flex w-full gap-4 last:mb-0"
                :class="{
                    'vut-container-loading': areItemsLoading,
                    'group': !areItemsLoading,
                }"
            >
                <div class="size-15 flex-none">
                    <CoreImg
                        :src="item?.imageUrl"
                        alt=""
                        loading="lazy"
                        :placeholder="[40, 25, 100, 4]"
                        class="aspect-square size-full !object-cover"
                        :width="60"
                    />
                </div>

                <div class="flex flex-1 flex-col gap-1">
                    <div class="vut-h4 group-hover:underline">
                        {{ areItemsLoading ? $t('labels.loading') : item?.name }}
                    </div>

                    <div>
                        <div v-for="(value, key) in item?.getValuesOfVariationProperties()"
                             :key="key"
                             class="vut-text-small font-normal !leading-tight"
                        >
                            {{ key.toString().toLowerCase() }}:
                            <span class="font-bold">
                                {{ value }}
                            </span>
                        </div>
                    </div>

                    <div class="vut-h4 text-gray self-end">
                        {{ areItemsLoading ? $t('labels.loading') : `${item?.amount} x ${item?.getFormattedDiscountedPrice()}` }}
                    </div>
                </div>
            </component>

            <template #footer>
                <BaseUiButton :to="localePath('cart')" class="w-full">
                    {{ $t('cart.continue_to.cart') }}
                </BaseUiButton>
            </template>
        </LazyUiPopupCard>
    </div>
</template>

<script lang="ts" setup>
import { NuxtLink, UtilExpandOnAppear } from '#components'

const {
    navbarEl,
    zIndex,
} = defineProps<{
    navbarEl: HTMLElement | undefined
    zIndex?: number
}>()

const localePath = useLocalePath()
const cartMenuId = useId()

const { cart, items, fetchItems, areItemsLoading } = useCart()

const renderCollapse = ref<boolean>(!cart.value)

// TODO: replace with `hover` implementation in BaseUiPopup
// https://github.com/simplo-sro/simploshop-fe/issues/596
const cartMenuButton = useTemplateRef<ComponentPublicInstance>('cartMenuButton')
const cartMenuPopup = useTemplateRef<ComponentPublicInstance>('cartMenuPopup')

const isButtonHovered = useElementHover(() => cartMenuButton.value?.$el, {
    delayLeave: 10,
})
const isPopupHovered = useElementHover(() => cartMenuPopup.value?.$el, {
    delayLeave: 10,
})

let autoClosePopupTimeout: NodeJS.Timeout | undefined
const isPopupOpenAsNotification = ref<boolean>(false)
const renderNotification = ref<boolean>(false)

const { is: isCartMenuDropdownOpen, was: isCartMenuDropdownMounted } = useLazyMount({
    get: () => isButtonHovered.value || isPopupHovered.value || isPopupOpenAsNotification.value,
    set: () => {},
})

watch(isCartMenuDropdownOpen, (val) => {
    if (!val) return
    renderNotification.value = false
    nextTick(() => {
        renderNotification.value = isPopupOpenAsNotification.value
    })
})

// close the popup if the cart button was interacted with by the user
watch([isButtonHovered, isPopupHovered], (vals) => {
    if (vals.some(Boolean)) return
    isPopupOpenAsNotification.value = false
    if (autoClosePopupTimeout) clearTimeout(autoClosePopupTimeout)
})

const events = useEvents()
events.useOn('cart:item-added', () => {
    if (autoClosePopupTimeout) clearTimeout(autoClosePopupTimeout)

    isPopupOpenAsNotification.value = true
    autoClosePopupTimeout = setTimeout(() => {
        isPopupOpenAsNotification.value = false
    }, 3000)
})

onBeforeUnmount(() => {
    if (autoClosePopupTimeout) clearTimeout(autoClosePopupTimeout)
})

const emit = defineEmits<{
    opened: []
}>()

watch(isCartMenuDropdownOpen, async (value) => {
    if (value) {
        emit('opened')
        await fetchItems()
    }
}, { immediate: true })
</script>

<style lang="scss" scoped>
.vut-cart__amount-indicator {
    position: absolute;
    right: -0.75rem;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $vut-c-secondary;
    height: 1rem;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    font-family: $vut-ff-secondary;

    @include vut-text-small;

    line-height: 1;

    &.v-enter-from,
    &.v-leave-to {
        transform: scale(0);
    }

    &.v-enter-active,
    &.v-leave-active {
        transition: transform $vut-trans-time $vut-timing;
    }
}

.vut-cart__popup-notification {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;

    background-color: $vut-c-green-lightest;
    color: $vut-c-green;

    &.v-enter-active {
        transition: opacity 300ms $vut-timing, transform 300ms $vut-timing;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
        transform: translateY(-0.5rem);
    }
}

</style>
