<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 18.605C8.85307 18.6052 7.71778 18.3752 6.66138 17.9286C5.60497 17.482 4.64897 16.8279 3.85 16.005C5.33341 14.4987 7.31438 13.5837 9.423 13.431C9.614 13.456 9.803 13.488 10 13.488C10.196 13.488 10.384 13.456 10.574 13.431C12.6841 13.5832 14.6666 14.4986 16.151 16.006C15.3517 16.8286 14.3955 17.4825 13.3389 17.9289C12.2824 18.3753 11.147 18.6052 10 18.605ZM9.996 12C9.804 12 9.613 12.022 9.422 12.035C8.7343 11.9001 8.1147 11.5308 7.66884 10.9901C7.22298 10.4494 6.97847 9.77081 6.977 9.07C6.97806 8.26858 7.29689 7.50028 7.86358 6.93359C8.43028 6.36689 9.19858 6.04806 10 6.047C10.8014 6.04806 11.5697 6.36689 12.1364 6.93359C12.7031 7.50028 13.0219 8.26858 13.023 9.07C13.0214 9.77123 12.7765 10.4502 12.33 10.9909C11.8835 11.5317 11.2632 11.9007 10.575 12.035C10.382 12.022 10.191 12.001 9.996 12ZM10 1.395C14.745 1.395 18.605 5.255 18.605 10C18.605 11.832 18.025 13.528 17.045 14.925C15.8658 13.7619 14.4186 12.9068 12.831 12.435C13.793 11.623 14.419 10.425 14.419 9.07C14.4179 7.89824 13.9521 6.77476 13.1236 5.94611C12.2951 5.11746 11.1718 4.65132 10 4.65C8.82824 4.65132 7.70487 5.11746 6.8764 5.94611C6.04794 6.77476 5.58206 7.89824 5.581 9.07C5.581 10.424 6.207 11.622 7.169 12.433C5.58103 12.9057 4.13358 13.7615 2.954 14.925C1.93878 13.4834 1.39423 11.7632 1.395 10C1.395 5.255 5.255 1.395 10 1.395ZM10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0Z"
        />
    </svg>
)
</script>
