<template>
    <CoreNavbar ref="coreNavbar" :navbar="navbar" header-class="vut-navbar">
        <!--  LOGO, SEARCH, AUTH, CART (top part)  -->
        <BaseContainerContent :padding="{ vertical: 'none', horizontal: 'safe-only' }" class="flex h-full bg-white">
            <!--  LOGO  -->
            <NavbarLogo />

            <!--  SEARCH  -->
            <NavbarSearch class="vut-navbar__item !flex flex-1" />

            <!--  ACTIONS  -->
            <!--            <NavbarLang class="vut-navbar&#45;&#45;item hidden lg:flex" />-->

            <div v-if="loggedIn" class="vut-navbar__item !hidden lg:!flex">
                <BaseUiButton
                    class="p-5 md:aspect-square"
                    size="auto"
                    color="white"
                    variant="sharp"
                    :to="localePath('customer-favorite-products')"
                    :aria-label="$t('accessibility.my_favorites')"
                >
                    <template #trailing>
                        <IconHeart width="20" height="19" />
                    </template>
                </BaseUiButton>
            </div>

            <!--  the z-index adjustment ensures that the incorrect menu popup is not re-opened
                  when the user quickly opens another menu after closing the previous one  -->
            <NavbarCustomer
                class="vut-navbar__item"
                :navbar-el="coreNavbar?.headerEl ?? undefined"
                :z-index="lastOpenedMenuItem === 'customer' ? -1 : -2"
                @opened="lastOpenedMenuItem = 'customer'"
            />

            <NavbarCart
                class="vut-navbar__item"
                :navbar-el="coreNavbar?.headerEl ?? undefined"
                :z-index="lastOpenedMenuItem === 'cart' ? -1 : -2"
                @opened="lastOpenedMenuItem = 'cart'"
            />

            <!--  MOBILE MENU BUTTON  -->
            <BaseUiButton
                ref="mobileMenuButton"
                class="vut-navbar__item !px-4 min-[450px]:!px-3 md:!px-8 lg:!hidden"
                color="white"
                variant="sharp"
                :aria-expanded="navbar.isMobileMenuOpen.value"
                :aria-controls="navId"
                :aria-label="navbar.isMobileMenuOpen.value ? $t('accessibility.close_mobile_menu') : $t('accessibility.open_mobile_menu')"
                @click="navbar.toggleMobileMenu"
            >
                <template #leading>
                    <IconMenu v-if="!navbar.isMobileMenuOpen.value"
                              width="14"
                              height="12"
                              class="text-secondary"
                    />
                    <IconCross v-else
                               width="14"
                               height="12"
                               class="text-secondary"
                    />
                </template>

                <span class="vut-text-small hidden min-[450px]:block">
                    {{ $t('labels.menu') }}
                </span>
            </BaseUiButton>
        </BaseContainerContent>

        <BaseFullscreenBackground :is-visible="navbar.isMobileMenuOpen.value" class="-z-10" />

        <!-- ------------------------------------------------------------------------ -->
        <!--  MOBILE MENU  -->
        <LazyNavbarMobileMenu
            v-if="navbar.wasMobileMenuMounted.value"
            :id="navId!"
            :open="navbar.isMobileMenuOpen.value"
        />
    </CoreNavbar>
</template>

<script lang="ts" setup>
import type { CoreNavbar } from '#components'

const isMobileWidth = useScssBreakpoints().smaller('lg')
const localePath = useLocalePath()
const { loggedIn } = useAuth()

const navbarButton = useTemplateRef<HTMLElement>('mobileMenuButton')

const coreNavbar = useTemplateRef<ComponentInstance<typeof CoreNavbar>>('coreNavbar')

const lastOpenedMenuItem = ref<'customer' | 'cart' | null>(null)

const navbar = useNavbar({
    mobileMenu: {
        closeIgnoreElements: () => [navbarButton.value],
        isMobileWidth: isMobileWidth,
        closeDuration: 300,
    },
})

const navId = useId()
</script>

<style lang="scss" scoped>
.vut-navbar {
    height: $vut-navbar-mobile-height;

    @include more-than('lg') {
        position: unset;
        height: $vut-navbar-height;
    }
}

.vut-navbar__item {
    // display: grid; makes the buttons keep their size in Safari on desktop
    display: grid;

    border-bottom: hr-line();

    &:not(:first-child, :nth-child(2)) {
        border-left: hr-line();
    }
}
</style>
