<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg fill="currentColor" viewBox="-0.25 -0.5 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.819 12.084a4.265 4.265 0 0 1-2.133-7.959v1.814a2.843 2.843 0 1 0 4.265 0V4.125a4.265 4.265 0 0 1-2.132 7.959zm-.711-9.241H8.53v4.976H7.108V2.843z"
        />
    </svg>
)
</script>
