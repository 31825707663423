<template>
    <footer class="vut-footer">
        <FooterNewsletter class="vut-footer__newsletter" />

        <CoreContainer :aria-label="$t('accessibility.footer')" class="vut-footer__menus">
            <BaseContainerContent :padding="{ horizontal: ['none', 'md:normal'], vertical: 'none' }" class="grid md:grid-cols-4 md:gap-12 xl:gap-32">
                <FooterMenu :menu="footerCategoriesMenuData" />
                <FooterMenu :menu="footerOrderMenuData" />
                <FooterMenu :menu="footerAccountMenuData" />
                <FooterMenu :menu="footerPagesMenuData" />
            </BaseContainerContent>
        </CoreContainer>

        <CoreContainer :aria-label="$t('accessibility.footer')" class="vut-footer__main">
            <BaseContainerContent :padding="{ vertical: 'none' }" class="grid items-center gap-6 md:grid-cols-2 lg:grid-cols-3">
                <NuxtLink :to="localePath('/')" class="w-fit">
                    <!-- TODO - use CoreImg (now not working correctly with svg) -->
                    <NuxtImg src="/images/vut.svg"
                             alt=""
                             loading="lazy"
                             class="h-20 w-full md:h-24"
                    />
                </NuxtLink>

                <div>
                    <h5 class="vut-h5 mb-3">
                        {{ $t('base.vut') }}
                    </h5>

                    <div class="flex gap-7">
                        <address>
                            {{ $t('base.address.street') }}
                            <br>
                            {{ $t('base.address.city') }}
                        </address>
                        <address>
                            <NuxtLink :to="$t('base.web')" target="_blank" class="vut-link vut-link--primary vut-link--underline font-bold">
                                {{ $t('base.web') }}
                            </NuxtLink>
                            <br>
                            <a :href="'mailto:' + $t('base.email')" class="vut-link vut-link--primary vut-link--underline font-bold">
                                {{ $t('base.email') }}
                            </a>
                        </address>
                    </div>
                </div>

                <FooterSocials />
            </BaseContainerContent>
        </CoreContainer>

        <FooterSignature class="vut-footer__signature vut-text-small" />
    </footer>
</template>

<script setup lang="ts">
import FooterSocials from '~/components/footer/FooterSocials.vue'

const localePath = useLocalePath()

const [
    { items: footerCategories },
] = await Promise.all([
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, null)
        .sortByAsc(CategoryModel.ATTR_POSITION)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
        ])
        .embed([
            CategoryModel.EMBED_URLS,
        ])
        .useGet({
            ssrCache: {
                key: 'footer-categories',
                ttl: 60 * 30,   // 30 min
                swr: true,
            },
        }),
])

const footerCategoriesMenuData = computed(() => {
    return {
        title: t('labels.categories'),
        links: footerCategories.value.map(category => ({
            label: category.name || '',
            url: category.urls || '',
        })),
    }
})

const { getStaticPageUrl } = useStaticPages()

const footerOrderMenuData = computed(() => {
    return {
        title: t('sections.footer.about_order'),
        links: [
            {
                label: t('sections.footer.shipping_options'),
                url: getStaticPageUrl('shipping'),
            }, {
                label: t('sections.footer.payment_options'),
                url: getStaticPageUrl('payment'),
            }, {
                label: t('sections.footer.return_policy'),
                url: getStaticPageUrl('returns'),
            }, {
                label: t('sections.footer.terms_conditions'),
                url: getStaticPageUrl('terms'),
            }, {
                label: t('sections.footer.complaints'),
                url: getStaticPageUrl('complaint'),
            }, {
                label: t('sections.footer.privacy_policy'),
                url: getStaticPageUrl('gdpr'),
            },
        ],
    }
})

const appConfig = useAppConfig()
const { t } = useI18n()
const { loggedIn } = useAuth()

const footerAccountMenuData = computed(() => {
    return {
        title: t('user.my_account'),
        links: loggedIn.value ? appConfig.userMenu.links.map(item => ({
            label: t(item.labelPath) || '',
            url: localePath(item.routeName) || '',
        })) : [
            {
                label: t('labels.login'),
                url: localePath('login'),
            }, {
                label: t('labels.registration'),
                url: localePath('register') }
            ,
        ],
    }
})

const footerPagesMenuData = computed(() => {
    return {
        title: t('sections.footer.useful_links'),
        links: [
            {
                label: t('pages.contact'),
                url: localePath('contact'),
            }, {
                label: t('sections.footer.faq'),
                url: getStaticPageUrl('faq'),
            }, {
                label: t('sections.footer.cookies_info'),
                url: getStaticPageUrl('cookies'),
            },/*  {
                label: t('sections.footer.cookies_settings'),
                url: 'TODO',
            }, */ {
                label: t('pages.site_map'),
                url: localePath('site-map'),
            },
        ],
    }
})
</script>

<style lang="scss" scoped>
/* TODO - maybe change padding to BaseContainerContent paddings */
.vut-footer__newsletter {
    padding: 2.25rem 0;
    border-top: hr-line();

    @include more-than(md) {
        padding: 4.25rem 0;
    }
}

.vut-footer__menus {
    @include more-than(md) {
        padding: 3rem 0;
        border-top: hr-line();
    }
}

.vut-footer__main {
    padding: 1rem 0 2.25rem;

    @include more-than(md) {
        padding: 3rem 0;
        border-top: hr-line();
    }
}

.vut-footer__signature {
    padding: 1.625rem 0;
    border-top: hr-line();

    @include more-than(md) {
        padding: 1.5rem 0;
    }
}
</style>
