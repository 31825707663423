<template>
    <div class="vut-content-layout">
        <CoreSkipToMainContent />

        <!--  NAVBAR  -->
        <NavbarComponent />

        <slot />

        <!--  FOOTER  -->
        <FooterComponent class="mt-auto" />
    </div>
</template>

<script lang="ts" setup>

const { page } = useCurrentPage()

const { t } = useI18n()

useHead({
    link: [
        // Preconnect to the image CDN
        // {
        //     rel: 'preconnect',
        //     href: 'https://vut.dev.simploshop.com',
        // },
    ],
})

useSetPageSeoProperties(page, {
    title: t('meta.titleSuffix'),
    description: t('meta.description'),
})

</script>

<style lang="scss" scoped>

.vut-content-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-height: 100dvh;

    max-width: 1440px;

    margin: auto;

    background: $vut-c-white;
}

</style>
