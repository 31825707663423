<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 17 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.63209 6.57333C1.63209 3.6992 3.9713 1.36 6.84543 1.36C9.72047 1.36 12.0588 3.6992 12.0588 6.57333C12.0573 7.95555 11.5076 9.28075 10.5302 10.2581C9.55284 11.2355 8.22765 11.7852 6.84543 11.7867C5.46328 11.785 4.13823 11.2352 3.1609 10.2579C2.18357 9.28054 1.63377 7.95548 1.63209 6.57333ZM16.4561 16.0933L11.5347 11.1719C12.7425 9.9461 13.4192 8.29419 13.4188 6.57333C13.4188 2.94848 10.4703 0 6.84543 0C3.22058 0 0.272095 2.94848 0.272095 6.57333C0.272095 10.1982 3.22058 13.1467 6.84543 13.1467C8.16777 13.148 9.45934 12.7477 10.5492 11.9988L15.5494 17L16.4561 16.0933Z"
        />
    </svg>
)
</script>
