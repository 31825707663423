<template>
    <NuxtLink :to="localePath('/')" class="vut-logo">
        <span class="visually-hidden">
            {{ $t('accessibility.logo_link') }}
        </span>

        <svg class="h-full max-h-full max-w-full lg:hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.76 47.66">
            <rect fill="#e4002b" width="47.66" height="47.66" />
            <rect x="49.6" width="85.16" height="47.66" />
            <path fill="#fff" d="m22.39,39.72h5.54v-18.7c0-1.9,1.65-3.44,3.68-3.44h8.26v-4.91h-17.49v27.06" />
            <rect fill="#fff"
                  x="7.66"
                  y="7.29"
                  width="14.73"
                  height="5.37"
            />
            <polyline fill="#fff" points="71.29 8.77 66.04 8.77 66.04 12.03 70.95 12.03 70.95 13.79 66.04 13.79 66.04 17.11 71.46 17.11 71.46 18.87 64 18.87 64 7.01 71.29 7.01 71.29 8.77" />
            <rect fill="#fff"
                  x="72.9"
                  y="13.38"
                  width="3.81"
                  height="1.61"
            />
            <path fill="#fff" d="m81.54,13.79c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-1.02c0-.43.07-.82.21-1.17.14-.35.34-.65.59-.91.26-.25.56-.45.91-.59.35-.14.74-.21,1.17-.21h2.46c.43,0,.82.07,1.17.21.35.14.65.34.91.59.25.25.45.56.59.91.14.35.21.74.21,1.17v.73h-2.03v-.83c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.12c-.31,0-.55.09-.74.28-.19.19-.28.43-.28.74v1.22c0,.31.09.55.28.74.19.19.43.28.74.28h2.37c.43,0,.82.07,1.17.21.35.14.65.34.91.59.25.26.45.56.59.91.14.35.21.74.21,1.17v1.08c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91-.25.26-.56.45-.91.59-.35.14-.74.21-1.17.21h-2.71c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-.73h2.03v.83c0,.31.09.55.28.74.19.19.43.28.74.28h2.37c.31,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-1.29c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.37" />
            <polyline fill="#fff" points="89.25 18.87 89.25 7.01 91.28 7.01 91.28 12.03 96.03 12.03 96.03 7.01 98.06 7.01 98.06 18.87 96.03 18.87 96.03 13.79 91.28 13.79 91.28 18.87 89.25 18.87" />
            <path fill="#fff" d="m103.4,18.87c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-6.1c0-.43.07-.82.21-1.17.14-.35.34-.65.59-.91.25-.25.56-.45.91-.59.35-.14.74-.21,1.17-.21h3.05c.43,0,.82.07,1.17.21.35.14.65.34.91.59s.45.56.59.91c.14.35.21.74.21,1.17v6.1c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91-.25.26-.56.45-.91.59-.35.14-.74.21-1.17.21h-3.05m2.88-1.76c.3,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-6.3c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.71c-.31,0-.55.09-.74.28-.19.19-.28.43-.28.74v6.3c0,.3.09.55.28.74.19.19.43.28.74.28h2.71Z" />
            <path fill="#fff" d="m111.79,7.01h5.25c.43,0,.82.07,1.17.21.35.14.65.34.91.59.25.25.45.56.59.91.14.35.21.74.21,1.17v1.52c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91s-.56.45-.91.59c-.35.14-.74.21-1.17.21h-3.22v4.58h-2.03V7.01m2.03,5.52h3.05c.3,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-1.73c0-.31-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-3.05v3.76Z" />
            <polyline fill="#fff" points="62.99 27.35 65.11 27.35 67.92 36.39 67.99 36.39 70.82 27.35 72.75 27.35 68.92 39.21 66.82 39.21 62.99 27.35" />
            <path fill="#fff" d="m80.97,27.35h2.03v8.98c0,.43-.07.82-.21,1.17s-.34.65-.59.91c-.25.26-.56.45-.91.59-.35.14-.74.21-1.17.21h-3.05c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-8.98h2.03v9.08c0,.31.09.55.28.74.19.19.43.28.74.28h2.71c.3,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-9.08" />
            <polyline fill="#fff" points="87.83 29.11 84.44 29.11 84.44 27.35 93.25 27.35 93.25 29.11 89.86 29.11 89.86 39.21 87.83 39.21 87.83 29.11" />
        </svg>

        <svg class="hidden h-full max-w-full lg:block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 169.35 47.66">
            <rect fill="#e4002b" width="47.66" height="47.66" />
            <rect x="49.6" width="119.75" height="47.66" />
            <path fill="#fff" d="m22.39,39.72h5.54v-18.7c0-1.9,1.65-3.44,3.68-3.44h8.26v-4.91h-17.49v27.06" />
            <rect fill="#fff"
                  x="7.66"
                  y="7.29"
                  width="14.73"
                  height="5.37"
            />
            <polyline fill="#fff" points="71.29 18.66 66.04 18.66 66.04 21.91 70.95 21.91 70.95 23.68 66.04 23.68 66.04 27 71.46 27 71.46 28.76 64.01 28.76 64.01 16.9 71.29 16.9 71.29 18.66" />
            <rect fill="#fff"
                  x="72.9"
                  y="23.27"
                  width="3.81"
                  height="1.61"
            />
            <path fill="#fff" d="m81.54,23.68c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59s-.45-.56-.59-.91c-.14-.35-.21-.74-.21-1.17v-1.02c0-.43.07-.82.21-1.17.14-.35.34-.65.59-.91s.56-.45.91-.59c.35-.14.74-.21,1.17-.21h2.46c.43,0,.82.07,1.17.21.35.14.65.34.91.59s.45.56.59.91c.14.35.21.74.21,1.17v.73h-2.03v-.83c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.12c-.31,0-.55.09-.74.28-.19.19-.28.43-.28.74v1.22c0,.3.09.55.28.74.19.19.43.28.74.28h2.37c.43,0,.82.07,1.17.21.35.14.65.34.91.59s.45.56.59.91c.14.35.21.74.21,1.17v1.08c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91s-.56.45-.91.59c-.35.14-.74.21-1.17.21h-2.71c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-.73h2.03v.83c0,.31.09.55.28.74.19.19.43.28.74.28h2.37c.31,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-1.29c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.37" />
            <polyline fill="#fff" points="89.25 28.76 89.25 16.9 91.28 16.9 91.28 21.91 96.03 21.91 96.03 16.9 98.06 16.9 98.06 28.76 96.03 28.76 96.03 23.68 91.28 23.68 91.28 28.76 89.25 28.76" />
            <path fill="#fff" d="m103.4,28.76c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.9-.59-.26-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-6.1c0-.43.07-.82.21-1.17.14-.35.34-.65.59-.91.25-.25.56-.45.9-.59.35-.14.74-.21,1.17-.21h3.05c.43,0,.82.07,1.17.21.35.14.65.34.91.59.26.25.45.56.59.91.14.35.21.74.21,1.17v6.1c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91-.25.26-.56.45-.91.59-.35.14-.74.21-1.17.21h-3.05m2.88-1.76c.31,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-6.3c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-2.71c-.3,0-.55.09-.74.28-.19.19-.28.43-.28.74v6.3c0,.3.09.55.28.74.19.19.43.28.74.28h2.71Z" />
            <path fill="#fff" d="m111.79,16.9h5.25c.43,0,.82.07,1.17.21.35.14.65.34.91.59.26.25.45.56.59.91.14.35.21.74.21,1.17v1.52c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91-.25.26-.56.45-.91.59-.35.14-.74.21-1.17.21h-3.22v4.58h-2.03v-11.86m2.03,5.52h3.05c.3,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-1.73c0-.3-.09-.55-.28-.74-.19-.19-.43-.28-.74-.28h-3.05v3.76Z" />
            <polyline fill="#fff" points="124.83 16.9 126.95 16.9 129.77 25.95 129.83 25.95 132.66 16.9 134.59 16.9 130.76 28.76 128.66 28.76 124.83 16.9" />
            <path fill="#fff" d="m142.81,16.9h2.03v8.98c0,.43-.07.82-.21,1.17-.14.35-.34.65-.59.91s-.56.45-.91.59c-.35.14-.74.21-1.17.21h-3.05c-.43,0-.82-.07-1.17-.21-.35-.14-.65-.34-.91-.59-.25-.25-.45-.56-.59-.91-.14-.35-.21-.74-.21-1.17v-8.98h2.03v9.08c0,.3.09.55.28.74.19.19.43.28.74.28h2.71c.31,0,.55-.09.74-.28.19-.19.28-.43.28-.74v-9.08" />
            <polyline fill="#fff" points="149.67 18.66 146.28 18.66 146.28 16.9 155.1 16.9 155.1 18.66 151.71 18.66 151.71 28.76 149.67 28.76 149.67 18.66" />
        </svg>
    </NuxtLink>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
</script>

<style scoped lang="scss">
    .vut-logo {
        display: flex;
        align-items: center;

        padding: 0.5rem 1rem;

        height: $vut-navbar-mobile-height;
        width: auto;

        border-bottom: hr-line();

        @include more-than-custom(375px) {
            padding: 0;
        }

        @include more-than('lg') {
            padding: 0;

            width: $vut-aside-width;
            height: $vut-navbar-height;

            border-bottom: 0;
        }
    }

    .vut-logo-border {
        border-bottom: hr-line();

        @include more-than('md') {
            border-bottom: unset;
        }
    }
</style>
