<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 14 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.888916" width="12.2222" height="1.22222" />
        <rect x="0.888916" y="3.88892" width="12.2222" height="1.22222" />
        <rect x="0.888916" y="7.77783" width="12.2222" height="1.22222" />
    </svg>
)
</script>
