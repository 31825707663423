<template>
    <div>
        <!--  Auth Button  -->
        <!--
            :key is important to re-render the component when its DOM structure changes internally
            so that `useElementHover` can detect the change and update the reference DOM element
            (otherwise the hover detection wouldn't work right after logging in)
        -->
        <BaseUiButton
            :key="loggedIn ? 'logged-in' : 'logged-out'"
            ref="userMenuButton"
            color="white"
            class="!p-4 md:aspect-square"
            :class="{
                'text-green': loggedIn
            }"
            size="auto"
            variant="sharp"
            :aria-controls="loggedIn ? userMenuId : undefined"
            :aria-expanded="loggedIn ? isUserMenuDropdownOpen : undefined"
            :aria-label="loggedIn ? $t('user.go_to_account') : $t('labels.open_login_modal')"
            :to="loggedIn ? localePath('customer-account') : undefined"
            :on-click="loggedIn ? blurActiveElement : () => openModal(LazyModalLogin)"
        >
            <template #trailing>
                <IconPerson class="size-4 sm:size-5" />
            </template>
        </BaseUiButton>

        <!--  USER MENU  -->
        <LazyUiPopupCard
            v-if="isUserMenuDropdownMounted && $refs.userMenuButton && loggedIn"
            :id="userMenuId"
            ref="userMenuPopup"
            v-model="isUserMenuDropdownOpen"
            :anchor="userMenuButton?.$el"
            :safe-padding="0"
            :boundary="navbarEl"
            placement="bottom-start"
            :offset="{
                mainAxis: 0,
                crossAxis: -1, // 1 px border offset from the reference button
            }"
            class="min-w-64"
            :z-index="zIndex"
        >
            <ul class="vut-ul-menu">
                <li v-for="link in appConfig.userMenu.links" :key="link.routeName">
                    <NuxtLink :to="localePath(link.routeName)" @click="blurActiveElement">
                        {{ $t(link.labelPath) }}
                    </NuxtLink>
                </li>
            </ul>

            <template #footer>
                <BaseUiButton
                    color="white"
                    size="small"
                    class="w-full !py-6"
                    align="left"
                    :on-click="logOut"
                >
                    <template #leading>
                        <IconPowerOff class="bg-gray rounded-full text-white" height="16" width="16" />
                    </template>

                    {{ $t('labels.log_out') }}
                </BaseUiButton>
            </template>
        </LazyUiPopupCard>
    </div>
</template>

<script lang="ts" setup>
import { LazyModalLogin } from '#components'

const {
    navbarEl,
    zIndex,
} = defineProps<{
    navbarEl: HTMLElement | undefined
    zIndex?: number
}>()

const userMenuId = useId()

const { openModal } = useModals()
const localePath = useLocalePath()
const { loggedIn, logOut } = useAuth()

// TODO: replace with `hover` implementation in BaseUiPopup
// https://github.com/simplo-sro/simploshop-fe/issues/596
const userMenuButton = useTemplateRef<ComponentPublicInstance>('userMenuButton')
const userMenuPopup = useTemplateRef<ComponentPublicInstance>('userMenuPopup')

const isButtonHovered = useElementHover(() => userMenuButton.value?.$el, {
    delayLeave: 10,
})
const isPopupHovered = useElementHover(() => userMenuPopup.value?.$el, {
    delayLeave: 10,
})

const { focused } = useFocus(userMenuButton)
const { focused: popupFocused } = useFocusWithin(userMenuPopup)

const { is: isUserMenuDropdownOpen, was: isUserMenuDropdownMounted } = useLazyMount({
    get: () => isButtonHovered.value || isPopupHovered.value || focused.value || popupFocused.value,
    set: () => {},
})

const emit = defineEmits<{
    opened: []
}>()

watch(isUserMenuDropdownOpen, (val) => {
    if (val) {
        emit('opened')
    }
}, { immediate: true })

const appConfig = useAppConfig()
</script>
