<template>
    <div
        v-show="!navbar.isMobileMenuOpenSynced.value"
        v-if="$slots.notices"
        :ref="navbar.noticeContainerRef"
        class="sim-notices"
        :class="noticeWrapperClass"
        v-bind="parentScope"
    >
        <slot name="notices" />
    </div>

    <header
        v-bind="parentScope"
        ref="headerEl"
        class="sim-navbar"
        :class="headerClass"
    >
        <slot />
    </header>
</template>

<script lang="ts" setup>
import type { NavbarInstance } from '@core-theme/app/composables/navbar'

defineOptions({
    inheritAttrs: false,
})

const headerEl = useTemplateRef<HTMLElement>('headerEl')

const {
    navbar,
    headerClass,
    noticeWrapperClass,
} = defineProps<{
    navbar: NavbarInstance,
    headerClass?: string
    noticeWrapperClass?: string
}>()

const parentScope = getScopeIdAttr()

defineExpose({
    headerEl,
})

</script>

<style lang="scss" scoped>

.sim-navbar {
    position: sticky;
    top: 0;
    isolation: isolate;
    box-sizing: border-box;
    width: 100%;
    z-index: 100;
}

.sim-notices {
    z-index: 200;
}

</style>
