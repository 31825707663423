<template>
    <CoreContainer :aria-label="$t('accessibility.copyright')">
        <BaseContainerContent :padding="{ vertical: 'none' }" class="grid gap-x-7 sm:grid-cols-2 lg:grid-cols-3">
            <div>
                {{ $t('sections.footer.copy', [year]) }}

                <CoreCreatedBySimplo orientation="row" variant="dark" :height="15" />
            </div>

            <div>
                <NuxtLink :to="getStaticPageUrl('gdpr')"
                          class="vut-link vut-link--primary vut-link--underline block w-fit"
                >
                    {{ $t('sections.footer.gdpr') }}
                </NuxtLink>
                <NuxtLink :to="getStaticPageUrl('cookies')"
                          class="vut-link vut-link--primary vut-link--underline block w-fit"
                >
                    {{ $t('sections.footer.cookies') }}
                </NuxtLink>
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>
const year = useState(() => new Date().getFullYear())

const { getStaticPageUrl } = useStaticPages()

</script>
